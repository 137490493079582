import React, { useEffect, useRef, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import dayjs from 'dayjs';
import Sortable from 'sortablejs';
import { PanelProps } from '@grafana/data';
import { Item, SimpleOptions } from '../types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import '../styles/tippy.css';
import '../styles/base.css';
import { getBackgroundColor } from './heatmapBackground';
import { useLocation } from 'react-router-dom';

// URLS

const getApiUrl = () => {
  const currentUrl = window.location.hostname;
  const urlParts = currentUrl.split('.');

  if (urlParts[0] === 'metrics') {
    return `https://api.${urlParts[1]}.${urlParts[2]}`;
  } else {
    return `https://${urlParts[0]}.${urlParts[1]}.${urlParts[2]}/api`;
  }
};

const apiUrl = getApiUrl();

const getMetricsUrl = () => {
  const currentUrl = window.location.hostname;
  const urlParts = currentUrl.split('.');
  
  if (urlParts[0] === 'metrics') {
    return `https://${urlParts[0]}.${urlParts[1]}.${urlParts[2]}`;
  } else {
    return `https://${urlParts[0]}.${urlParts[1]}.${urlParts[2]}/metrics`;
  }
};

const metricsUrl = getMetricsUrl();

// STYLES

const getStyles = (columns: number, itemWidth: number, itemHeight: number) => ({
  sortableList: css`
    display: grid;
    grid-template-columns: repeat(${columns}, ${itemWidth}px);
    gap: 11.4px;
    padding: 22px 0 20px 0;
    list-style: none;
    border-bottom: 1px dashed #4e4e4e;
    border-top: 1px dashed #4e4e4e;
  `,
  sortableItem: css`
    width: ${itemWidth}px;
    height: ${itemHeight}px;
    border: 2px solid rgb(255 255 255 / 60%);
    cursor: move;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85em;
    background-color: #116411;
    position: relative;
    transition: background-color 0.25s, transform 0.25s;
    font-family: monospace;
    color: #c9c9c9;
    padding-top: 1px;
    &:hover {
      background-color: darkgreen;
      transform: scale(1.09);
    }
  `,
  poolStyle: (pool: number) => css`
    color: ${pool === 4 ? '#116411' : pool === 3 ? '#ba8b26' : '#de0505'};
  `,
  tempStyle: (temp: number) => css`
    color: ${temp < 30 ? '#de0505' : temp < 50 ? '#ba8b26' : temp < 70 ? '#116411' : temp < 80 ? '#ba8b26' : '#de0505'};
    background-color: ${temp < 30 || temp > 80 ? '#ba8b26' : 'transparent'};
  `,
  fansStyle: (fans: number) => css`
    color: ${fans < 65 ? '#116411' : fans < 95 ? '#ba8b26' : '#de0505'};
    background-color: ${fans > 95 ? '#ba8b26' : 'transparent'};
  `,
  chipsStyle: (chips: string) => {
    if (!chips || chips === '0/0') return css`color: #de0505;`;
  
    const [actual, expected] = chips.split('/').map(Number);
    if (actual === expected) return css`color: #116411;`;
    if (actual >= expected * 0.9) return css`color: #ba8b26;`;
    return css`color: #de0505;`;
  },
  boardsStyle: (boards: string) => {
    if (!boards || boards === '0/0') return css`color: #de0505;`;
  
    const [actual, expected] = boards.split('/').map(Number);
    if (actual === expected) return css`color: #116411;`;
    if (actual === expected - 1) return css`color: #ba8b26;`;
    return css`color: #de0505;`;
  },
});

const getChipStyleAndFaults = (chips: string) => {
  if (!chips || chips === '0/0') {
    return { color: '#de0505', fault: true };
  }

  const [actual, expected] = chips.split('/').map(Number);
  if (actual === expected) {
    return { color: '#116411', fault: false };
  }
  if (actual >= expected * 0.9) {
    return { color: '#ba8b26', fault: false };
  }
  return { color: '#de0505', fault: true };
};

const getBoardStyleAndFaults = (boards: string) => {
  if (!boards || boards === '0/0') {
    return { color: '#de0505', fault: true };
  }

  const [actual, expected] = boards.split('/').map(Number);
  if (actual === expected) {
    return { color: '#116411', fault: false };
  }
  if (actual === expected - 1) {
    return { color: '#ba8b26', fault: false };
  }
  return { color: '#de0505', fault: true };
};

const getFaultStatus = (chips: string, boards: string): boolean => {
  const chipFault = getChipStyleAndFaults(chips).fault;
  const boardFault = getBoardStyleAndFaults(boards).fault;
  return chipFault || boardFault;
};

const getCombinedStyle = (
  chips: string, 
  boards: string, 
  temp: number, 
  fans: number, 
  heatMapMode: boolean, 
  activePool: number | null, 
  offline: boolean
) => {
  const chipStyle = getChipStyleAndFaults(chips);
  const boardStyle = getBoardStyleAndFaults(boards);
  const hasFault = chipStyle.fault || boardStyle.fault;

  const backgroundColor = heatMapMode
    ? getBackgroundColor(temp, hasFault, chipStyle.color, boardStyle.color, heatMapMode)
    : offline
    ? '#212121'
    : hasFault
    ? '#de0505'
    : activePool === 3
    ? '#b553aac7'
    : activePool === 1 || activePool === 2
    ? '#679f67'
    : temp > 80
    ? '#ba8b26'
    : fans > 95
    ? '#70851a'
    : getBackgroundColor(temp, hasFault, chipStyle.color, boardStyle.color, heatMapMode);

  return {
    backgroundColor,
    // fontSize: !heatMapMode && hasFault ? '1.25em' : undefined,
  };
};

///////////////////////////////////////////
// P A N E L
/////////////////////////////////////////

const SimplePanel: React.FC<PanelProps<SimpleOptions>> = ({ options, data, onOptionsChange }) => {
  const [heatMapMode, setHeatMapMode] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const [dragging, setDragging] = useState(false);
  const [itemOrder, setItemOrder] = useState<string[]>([]);
  const prevOptionsRef = useRef<SimpleOptions | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const devicesetId = queryParams.get('var-deviceset_id') || 'default-deviceset-id';
  const panelId = data.request?.panelId || 'default-panel-id';
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // OPTiONS :: iNiT

  const [localOptions, setLocalOptions] = useState(options);

  const {
    columns,
    rows,
    itemWidth,
    itemHeight,
    start_ip_segment_3,
    start_ip_segment_4,
    end_ip_segment_3,
    end_ip_segment_4,
    showItemIP,
    showPool,
    showHashrate,
    showChipsBoards,
    showTempFans,
    showLocation,
    showActionButtons
  } = localOptions;
  
  useEffect(() => {
    if (
      columns !== options.columns ||
      rows !== options.rows ||
      itemWidth !== options.itemWidth ||
      itemHeight !== options.itemHeight ||
      start_ip_segment_3 !== options.start_ip_segment_3 ||
      start_ip_segment_4 !== options.start_ip_segment_4 ||
      end_ip_segment_3 !== options.end_ip_segment_3 ||
      end_ip_segment_4 !== options.end_ip_segment_4 ||
      showItemIP !== options.showItemIP ||
      showPool !== options.showPool ||
      showHashrate !== options.showHashrate ||
      showChipsBoards !== options.showChipsBoards ||
      showTempFans !== options.showTempFans ||
      showLocation !== options.showLocation ||
      showActionButtons !== options.showActionButtons
    ) {
      handleOptionsChange(options);
      setLocalOptions(options);
    }
  }, [
    options.columns,
    options.rows,
    options.itemWidth,
    options.itemHeight,
    options.start_ip_segment_3,
    options.start_ip_segment_4,
    options.end_ip_segment_3,
    options.end_ip_segment_4,
    options.showItemIP,
    options.showPool,
    options.showHashrate,
    options.showChipsBoards,
    options.showTempFans,
    options.showLocation,
    options.showActionButtons
  ]);
  
  useEffect(() => {
    // console.log('localOptions have been updated:', localOptions);
  }, [localOptions]);

  // OPTiONS :: LOAD

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const response = await fetch(`${apiUrl}/sitemap/options/${devicesetId}/${panelId}`);

        if (response.ok) {
          const parsedOptions = await response.json();
          const options = parsedOptions.options ? JSON.parse(parsedOptions.options) : {};
          onOptionsChange(options);
          setInitialLoadComplete(true);
        } else if (response.status === 404) {
          setInitialLoadComplete(true);
          const newOptions = { ...options };
          await handleOptionsChange(newOptions);
        }
      } catch (error) {
        console.error('Error loading/updating options:', error);
        setInitialLoadComplete(true);
      }
    };

    loadOptions();
  }, [devicesetId, panelId, onOptionsChange]);

  // OPTiONS :: HANDLE OPTiONS CHANGE

  const handleOptionsChange = async (newOptions: SimpleOptions) => {
    try {
      await fetch(`${apiUrl}/sitemap/options/${devicesetId}/${panelId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          options: JSON.stringify(newOptions)
        }),
      });
      setLocalOptions(newOptions);
    } catch (error) {
      console.error('Error saving options:', error);
    }
  };
 
  // BTN ACTiONS

  const handleSortOrderReset = async () => {
    try {
        await fetch(`${apiUrl}/sitemap/sortorder/${devicesetId}/${panelId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sortorder: '',
                options: null
            }),
        });
        setItemOrder([]);
    } catch (error) {
        console.error('Error resetting sort order:', error);
    }
  };

  const handleToggleHeatMap = () => {
    setHeatMapMode(prevMode => {
      const newHeatMapMode = !prevMode;
      if (initialLoadComplete) {
        const newOptions = { ...options, heatMapMode: newHeatMapMode };
        handleOptionsChange(newOptions);
      }
      return newHeatMapMode;
    });
  };

  const handleCurtail = () => {
    alert('Curtail action triggered');
  };

  const handleReboot = () => {
    alert('Reboot action triggered');
  };

  // MOUSE ACTiONS

  const handleMouseDown = (e: React.MouseEvent) => {
    if (dragging) {
      e.preventDefault();
    }
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleClick = (deviceId: string) => {
    if (!dragging) {
      const url = `${metricsUrl}/d/fa250095-6c86-411d-9761-e242bec19a12/asic-detail?var-device_id=${deviceId}`;
      window.open(url, '_blank');
    }
  };

  // iP

  const ipToNumber = (ip: string) => {
    const parts = ip.split('.').map(Number);
    return (parts[0] << 24) | (parts[1] << 16) | (parts[2] << 8) | parts[3];
  };

  const numberToIp = (num: number) => {
    return `${(num >>> 24) & 255}.${(num >>> 16) & 255}.${(num >>> 8) & 255}.${num & 255}`;
  };

  const baseIp = useMemo(() => {
    if (data.series.length === 0) {
      return '';
    }
  
    const series = data.series[0];
    const ipField = series.fields.find(field => field.name === 'ip_address');
    if (!ipField) {
      return '';
    }
    const ipValues = ipField.values.toArray() as string[];
    return ipValues[0].split('.').slice(0, 2).join('.');
  }, [data]);
  
  const defaultThirdSegment = useMemo(() => {
    if (data.series.length === 0) {
      return '0';
    }
  
    const series = data.series[0];
    const ipField = series.fields.find(field => field.name === 'ip_address');
    if (!ipField) {
      return '0';
    }
    const ipValues = ipField.values.toArray() as string[];
    return ipValues[0].split('.')[2];
  }, [data]);

  // MiNERS

  const generateData = (miners: any[]) => {
    return miners.map(miner => {
      const data = {
        color: miner.is_curtailed ? 'curtailed' : '#116411',
        ip: miner.ip_address ? miner.ip_address.split('.').pop() || 'No IP' : 'No IP',
        fullIp: miner.ip_address || 'No IP',
        isCurtailed: miner.is_curtailed,
        deviceId: miner.device_id || `placeholder-${miner.ip_address?.split('.').pop()}`,
        hashrate: miner.hashrate_ghs_1m || 0,
        devicesetId: miner.deviceset_id,
        model: miner.model,
        chips: miner.chips,
        boards: miner.boards,
        pool: miner.pool,
        active_pool: miner.active_pool,
        offline: miner.offline,
        stats_timestamp: miner.stats_timestamp,
        temp: Math.round(Number(miner.temperatures)),
        fans: Math.round(Number(miner.fanspeeds)),
        firmware: miner.platform_name,
        os_version: miner.os_version,
        load: Number(miner.power_consumption / 1000).toFixed(2),
        efficiency: Math.round(Number(miner.efficiency)),
        algo: Number(miner.algo),
        algo_name: miner.algo_name,
        algo_unit: miner.algo_unit,
        row: miner.row || 0,
        col: miner.col || 0
      };
      return data;
    });
  };

  // MiNERS :: LiST

  const miners = useMemo(() => {
    if (data.series.length === 0) {
      return [];
    }
  
    const series = data.series[0];
    const ipField = series.fields.find(field => field.name === 'ip_address');
    const curtailedField = series.fields.find(field => field.name === 'is_curtailed');
    const deviceIdField = series.fields.find(field => field.name === 'DEViD');
    const devicesetIdField = series.fields.find(field => field.name === 'iD');
    const hashrateField = series.fields.find(field => field.name === 'HR / LIVE');
    const modelField = series.fields.find(field => field.name === 'MODEL');
    const chipsField = series.fields.find(field => field.name === 'CHIPS');
    const boardsField = series.fields.find(field => field.name === 'BOARDS');
    const poolField = series.fields.find(field => field.name === 'POOL');
    const activePoolField = series.fields.find(field => field.name === 'ACTIVE POOL');
    const offlineField = series.fields.find(field => field.name === 'OFFLINE');
    const statsTimestampField = series.fields.find(field => field.name === 'STATS_TIMESTAMP');
    const tempField = series.fields.find(field => field.name === 'TEMP');
    const fansField = series.fields.find(field => field.name === 'FANS');
    const firmwareField = series.fields.find(field => field.name === 'FW');
    const osVersionField = series.fields.find(field => field.name === 'OS VERSION');
    const loadField = series.fields.find(field => field.name === 'POWER');
    const efficiencyField = series.fields.find(field => field.name === 'EFFICIENCY');
    const algoField = series.fields.find(field => field.name === 'ALGO');
    const algoNameField = series.fields.find(field => field.name === 'ALGO_NAME');
    const algoUnitField = series.fields.find(field => field.name === 'ALGO_UNIT');
  
    if (!ipField || !curtailedField || !deviceIdField || !devicesetIdField || !hashrateField || !modelField || !chipsField || !boardsField || !poolField || !activePoolField || !offlineField || !statsTimestampField || !tempField || !fansField || !firmwareField || !osVersionField || !loadField || !efficiencyField || !algoField || !algoNameField || !algoUnitField) {
      return [];
    }
  
    const ipValues = ipField.values.toArray() as string[];
    const curtailedValues = curtailedField.values.toArray() as number[];
    const deviceIdValues = deviceIdField.values.toArray() as string[];
    const devicesetIdValues = devicesetIdField.values.toArray() as string[];
    const hashrateValues = hashrateField.values.toArray() as string[];
    const modelValues = modelField.values.toArray() as string[];
    const chipsValues = chipsField.values.toArray() as string[];
    const boardsValues = boardsField.values.toArray() as string[];
    const poolValues = poolField.values.toArray() as string[];
    const activePoolValues = activePoolField.values.toArray() as string[];
    const offlineValues = offlineField.values.toArray() as string[];
    const statsTimestampValues = statsTimestampField.values.toArray() as string[];
    const tempValues = tempField.values.toArray() as string[];
    const fansValues = fansField.values.toArray() as string[];
    const firmwareValues = firmwareField.values.toArray() as string[];
    const osVersionValues = osVersionField.values.toArray() as string[];
    const loadValues = loadField.values.toArray() as string[];
    const efficiencyValues = efficiencyField.values.toArray() as string[];
    const algoValues = algoField.values.toArray() as number[];
    const algoNameValues = algoNameField.values.toArray() as string[];
    const algoUnitValues = algoUnitField.values.toArray() as string[];
  
    const startIp = `${baseIp}.${options.start_ip_segment_3 || defaultThirdSegment}.${options.start_ip_segment_4 || 1}`;
    const endIp = `${baseIp}.${options.end_ip_segment_3 || defaultThirdSegment}.${options.end_ip_segment_4 || 254}`;
  
    const startIpNumber = ipToNumber(startIp);
    const endIpNumber = ipToNumber(endIp);
  
    const minerMap = ipValues.reduce((map, ip, index) => {
      map[ipToNumber(ip)] = {
        ip_address: ip,
        is_curtailed: curtailedValues[index] === 1,
        device_id: deviceIdValues[index],
        deviceset_id: devicesetIdValues[index],
        hashrate_ghs_1m: hashrateValues[index],
        model: modelValues[index],
        chips: chipsValues[index],
        boards: boardsValues[index],
        pool: poolValues[index],
        active_pool: activePoolValues[index],
        offline: offlineValues[index],
        stats_timestamp: statsTimestampValues[index],
        temperatures: tempValues[index],
        fanspeeds: fansValues[index],
        platform_name: firmwareValues[index],
        os_version: osVersionValues[index],
        power_consumption: loadValues[index],
        efficiency: efficiencyValues[index],
        algo: algoValues[index],
        algo_name: algoNameValues[index],
        algo_unit: algoUnitValues[index],
      };
      return map;
    }, {} as Record<number, any>);
  
    const filledMiners = [];
  
    for (let ipNumber = startIpNumber; ipNumber <= endIpNumber; ipNumber++) {
      let miner = minerMap[ipNumber];
      if (!miner) {
        miner = {
          ip_address: numberToIp(ipNumber),
          is_curtailed: false,
          device_id: null,
          deviceset_id: null,
          hashrate_ghs_1m: null,
          model: null,
          chips: null,
          boards: null,
          pool: null,
          active_pool: null,
          offline: true,
          stats_timestamp: null,
          temperatures: null,
          fanspeeds: null,
          platform_name: null,
          os_version: null,
          power_consumption: null,
          efficiency: null,
          algo: null,
          algo_name: null,
          algo_unit: null,
        };
      }
      
      // SET ROW/COL

      const index = ipNumber - startIpNumber;
      const column = (index % options.columns) + 1;
      const row = Math.floor(index / options.columns) + 1;

      filledMiners.push({
        ...miner,
        row,
        col: column,
      });
    }
  
    return filledMiners;
  }, [
    data,
    options.start_ip_segment_3,
    options.start_ip_segment_4,
    options.end_ip_segment_3,
    options.end_ip_segment_4,
    baseIp,
    defaultThirdSegment,
    options.columns
  ]);

  useEffect(() => {
    if (miners.length > 0) {
      const firstMinerThirdSegment = parseInt(miners[0].ip_address.split('.')[2], 10);
      const lastMinerThirdSegment = parseInt(miners[miners.length - 1].ip_address.split('.')[2], 10);
  
      const newOptions: SimpleOptions = {
        ...options,
        start_ip_segment_3: options.start_ip_segment_3 ?? firstMinerThirdSegment,
        end_ip_segment_3: options.end_ip_segment_3 ?? lastMinerThirdSegment,
        start_ip_segment_4: options.start_ip_segment_4 ?? 1,
        end_ip_segment_4: options.end_ip_segment_4 ?? 254,
      };
  
      const prevOptions = prevOptionsRef.current;
  
      const optionsChanged =
        !prevOptions ||
        newOptions.start_ip_segment_3 !== prevOptions.start_ip_segment_3 ||
        newOptions.end_ip_segment_3 !== prevOptions.end_ip_segment_3 ||
        newOptions.start_ip_segment_4 !== prevOptions.start_ip_segment_4 ||
        newOptions.end_ip_segment_4 !== prevOptions.end_ip_segment_4;
  
      if (optionsChanged) {
        onOptionsChange(newOptions);
        prevOptionsRef.current = newOptions;
      }
    }
  }, [miners, options, onOptionsChange]);

  const dataWithColors = useMemo(() => {
    const generatedData = generateData(miners);
  
    if (itemOrder.length > 0) {
      return itemOrder.map(deviceId => {
        const foundData = generatedData.find(data => data.deviceId === deviceId);
  
        return foundData || {
          ip: `n/a-${deviceId.split('-').pop()}`,
          fullIp: `n/a-${deviceId.split('-').pop()}`,
          isCurtailed: false,
          deviceId: deviceId || `placeholder-${Math.random()}`,
          devicesetId: 'n/a',
          hashrate: 'n/a',
          model: 'n/a',
          chips: 'n/a',
          boards: 'n/a',
          pool: 'n/a',
          active_pool: 'n/a',
          offline: 'n/a',
          stats_timestamp: 'n/a',
          temp: 0,
          fans: 0,
          firmware: 'n/a',
          os_version: 'n/a',
          load: 'n/a',
          efficiency: 'n/a',
          algo: 0,
          algo_name: 'n/a',
          algo_unit: 'n/a',
          color: '#116411',
          col: undefined,
          row: undefined
        };
      });
    }
  
    return generatedData;
  }, [miners, itemOrder]);  

  const styles = useMemo(
    () => getStyles(options.columns, options.itemWidth, options.itemHeight),
    [options.columns, options.itemWidth, options.itemHeight]
  );
  
  // SORT-ORDER :: LOAD/SET

  useEffect(() => {

    const loadSortOrder = async () => {
      try {
        const response = await fetch(`${apiUrl}/sitemap/sortorder/${devicesetId}/${panelId}`);
        if (response.ok) {
          const parsedOrder = await response.json();
          const sortorder = JSON.parse(parsedOrder.sortorder);
          setItemOrder(sortorder.itemOrder || []);
        }
      } catch (error) {
        console.error('Error loading sort order:', error);
      }
    };
  
    loadSortOrder();
  
    if (listRef.current) {
      const sortableInstance = Sortable.create(listRef.current, {
        animation: 150,
        group: 'shared',
        onStart: () => setDragging(true),
        onEnd: async () => {
          setDragging(false);
          const newOrder = Array.from(listRef.current!.children).map(
            (child: any) => child.dataset.deviceId
          );
  
          setItemOrder(newOrder);
  
          try {
            await fetch(`${apiUrl}/sitemap/sortorder/${devicesetId}/${panelId}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                sortorder: JSON.stringify({ itemOrder: newOrder }),
                options: null
              }),
            });
          } catch (error) {
            console.error('Error saving sort order:', error);
          }
        },
      });
  
      return () => {
        sortableInstance.destroy();
      };
    }
  
    return undefined;
  }, [devicesetId, panelId]);

  // RENDER-CONTENT

  const renderContent = (
    item: Item,
    options: SimpleOptions,
    heatMapMode: boolean,
    styles: any
  ): string | JSX.Element => {
    if (heatMapMode) {
      return <span className={styles.heatmapTemp}>{item.temp}°C</span>;
    } else if (item.isCurtailed) {
      return '⚡';
    } else if (parseInt(item.active_pool) === 3) {
      return '$';
    } else if (getFaultStatus(item.chips, item.boards)) {
      return '▤';
    } else if (parseInt(item.active_pool) === 1 || parseInt(item.active_pool) === 2) {
      return '♻️';
    } else if (item.temp > 85) {
      return '🔥';
    } else if (item.temp < 30 || item.temp > 80) {
      return '🌡️';
    } else if (item.fans > 95) {
      return '𖣘';
    } else {
      return options.showItemIP ? item.ip : '';
    }
  };

  // OUTPUT

  return (
    <div className="wrapper">
      <div className="ipRangeTextWrapper">
        <div className="ipRangeText">
          {miners.length > 0 ? 
            `${miners[0].ip_address.split('.').slice(0, 3).join('.')}.${options.start_ip_segment_4 || '1'} - ${miners[miners.length - 1].ip_address.split('.').slice(0, 3).join('.')}.${options.end_ip_segment_4 || '254'}` 
            : 'No ASICs in range!'}
        </div>
        <button className="button_reset_order" onClick={handleSortOrderReset} title="Reset order.">🔄</button>
      </div>
      <div className="ipRangeSpacer"></div>
      <ul ref={listRef} className={`${styles.sortableList}`}>
        {dataWithColors.map((item) => {
          const isPlaceholder = !item.deviceId || item.deviceId.startsWith('placeholder-');
          const lastIpSegment = item.fullIp ? item.fullIp.split('.').pop() : '?';

          return isPlaceholder ? (
            <Tippy
              key={item.deviceId || `placeholder-${lastIpSegment}`}
              content={(
                <div>
                  <div className="tippy-head-span">
                   🚫 {item.fullIp}
                  </div>
                  <div>
                   <b>LOCATION .:</b> <b className="tippy-mono-small">R-</b><b>{item.row}</b> <b className="tippy-mono-small">C-</b><b>{item.col}</b>
                  </div>
                  <div className="tippy-foot-span">
                    &laquo; No ASIC &raquo;
                  </div>
                </div>
              )}
              arrow={true}
              placement="top"
              theme="light"
              className="fadeInOutAnimation"
            >
              <li
                data-device-id={item.deviceId}
                className={`${styles.sortableItem} placeholderItem`}
                style={{ backgroundColor: '#f0f0f0', border: '1px dashed #ccc' }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                <div className="placeholderContent">{lastIpSegment}</div>
              </li>
            </Tippy>
          ) : (
            <Tippy
              key={item.deviceId}
              content={
                !dragging && item.deviceId !== 'n/a' && (
                  <div>
                    <div className="tippy-head-span">
                      {item.offline === 1 ? '🚫 ' : item.isCurtailed ? '⚡ ' : '🌐 '}{item.fullIp}
                    </div>
                    <div className="tippy-value-span-with-border">
                      <b>MINER ....:</b> <b>{item.model}</b>
                    </div>
                    {options.showPool && (
                      <div>
                        <b>POOL .....: </b>
                        <b className={`${styles.poolStyle(item.pool)}`}>
                          {item.active_pool === null || item.active_pool === undefined
                            ? '#?'
                            : parseInt(item.active_pool) + 1 === 3
                            ? 'FEE?'
                            : '#' + (parseInt(item.active_pool) + 1)}
                        </b>
                        <b className="tippy-mono-small">
                          {parseInt(item.pool) === 4 ? ' ✅' :
                            parseInt(item.pool) === 3 ? ' 🔄' :
                            parseInt(item.pool) === 2 ? ' 💀' :
                            ' ❌'}
                        </b>
                      </div>
                    )}
                    {options.showHashrate && (
                      <div>
                        <div>
                          <b>HASHRATE .:</b> <b>{item.hashrate}</b><b className="tippy-mono-small"> {item.algo_unit}/s</b>
                        </div>
                        <div className="tippy-value-span-with-border">
                          <b>ALGO .....:</b> <b>{item.algo_name}</b>
                        </div>
                      </div>
                    )}
                    {options.showChipsBoards && (
                      <>
                        <div>
                          <b>CHIPS ....:</b> <b className={`${styles.chipsStyle(item.chips)} trans`}>{item.chips ?? 'n/a'}</b>
                        </div>
                        <div className="tippy-value-span-with-border">
                          <b>BOARDS ...:</b> <b className={`${styles.boardsStyle(item.boards)} trans`}>{item.boards ?? 'n/a'}</b>
                        </div>
                      </>
                    )}
                    {options.showTempFans && (
                      <>
                        <div>
                          <b>TEMP .....:</b> <b className={`${styles.tempStyle(item.temp)} trans`}>{item.temp}</b>
                          <b className={`${styles.tempStyle(item.temp)} tippy-mono-small trans`}>°C</b>
                        </div>
                        <div className="tippy-value-span-with-border">
                          <b>FANS .....:</b> <b className={`${styles.fansStyle(item.fans)} trans`}>{item.fans}</b>
                          <b className={`${styles.fansStyle(item.fans)} tippy-mono-small trans`}>%</b>
                        </div>
                      </>
                    )}
                    {item.offline === 0 ? (
                      <>
                        <div>
                          <b>LOAD .....:</b> <b>{item.load}</b><b className="tippy-mono-small"> kW</b>
                        </div>
                        <div>
                          <b>EFF ......:</b> 
                          <b>
                            {item.algo === 2 
                              ? " " + (Number(item.efficiency) / 1000).toFixed(2)
                              : " " + item.efficiency}
                          </b>
                          <b className="tippy-mono-small">
                            {item.algo === 2 ? ' W/MH' : ' W/TH'}
                          </b>
                        </div>
                      </>
                    ) : (
                      <div>
                        <b>OFFLINE ..:</b> {Math.floor(dayjs().diff(dayjs(item.stats_timestamp * 1000), 'minute'))}
                        <b className="tippy-mono-small"> min</b>
                      </div>
                    )}
                    {options.showLocation && (
                      <>
                        <div className="tippy-value-span-with-border-top">
                          <b>LOCATION .:</b> <b className="tippy-mono-small">R-</b><b>{item.row}</b> <b className="tippy-mono-small">C-</b><b>{item.col}</b>
                        </div>
                      </>
                    )}
                    <div className="tippy-foot-span">
                      {item.firmware} ({item.os_version})
                    </div>
                  </div>
                )
              }
              arrow={true}
              placement="top"
              theme="light"
              className="fadeInOutAnimation"
            >
              <li
                data-device-id={item.deviceId}
                className={`${styles.sortableItem} ${item.color === 'curtailed' ? 'curtailedItem' : ''}`}
                style={getCombinedStyle(item.chips, item.boards, item.temp, item.fans, heatMapMode, item.active_pool, item.offline)}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onClick={() => handleClick(item.deviceId)}
              >
                {renderContent(item, options, heatMapMode, styles)}
              </li>
            </Tippy>
          );
        })}
      </ul>
      <div className="buttonContainerHeatmap">
        <button className="buttonFullWidth" onClick={handleToggleHeatMap}>
          {heatMapMode ? '⚒️ TOGGLE DETAILS' : '🌡️ TOGGLE HEATMAP'}
        </button>
      </div>
      {options.showActionButtons && (
      <>
        <div className="buttonContainer">
          <button className="button" onClick={handleCurtail}>⚡ CURTAIL</button>
          <button className="button" onClick={handleReboot}>↻ REBOOT</button>
        </div>
      </>
      )}
    </div>
  );  
};

export default SimplePanel;